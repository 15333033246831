<template>
  <div style="height: 60vh;">
    <div class="card card-custom gutter-b" style="height: 60vh; overflow-y: auto;">
      <div class="card-header border-0 py-5">
        <h3 class="card-title font-weight-bolder text-dark">
          Weeks
          <span class="ml-5"
            ><span
              class="label label-lg label-inline label-light-primary font-weight-bold"
              >{{ range.start | formatDate }}</span
            ></span
          >
          <span
            ><span class="flex-shrink-0 my-2 mx-3"
              ><i class="la la-arrow-right"></i></span
          ></span>
          <span
            ><span
              class="label label-lg label-inline label-light-primary font-weight-bold"
              >{{ range.end | formatDate }}</span
            ></span
          >
        </h3>
        <div class="card-toolbar"></div>
      </div>
      <div class="card-body mx-0 px-0">
        <apexchart
          v-if="isReady"
          :options="options"
          :series="series"
        ></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
import { getWeeksMeta } from '@/core/helpers/helpers'
import parse from 'date-fns/parse'
import getWeek from 'date-fns/getWeek'
import VueApexCharts from 'vue-apexcharts'
import { mapGetters } from 'vuex'

export default {
  name: 'WeekTraffic',
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    traffic: {
      type: Array,
    },
    range: {
      type: Object,
    },
  },
  data() {
    return {
      transactions: [],
      weeks: [],
      options: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: 0,
          offsetY: 0,
          formatter(val) {
            if (val !== null) {
              return val
                .toFixed(2)
                .replace('.', ',')
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
            }
            return val
          },
        },
        xaxis: {
          categories: [],
          labels: {
            show: true,
            style: {
              fontSize: '12px',
              fontFamily: 'Poppins, Helvetica, "sans-serif"',
              fontWeight: 400,
              cssClass: 'week-label',
            },
            formatter(value) {
              return value
            },
          },
        },
        yaxis: {
          show: true,
          labels: {
            show: true,
            formatter(val) {
              if (val) {
                return (
                  val
                    .toFixed(2)
                    .replace('.', ',')
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
                )
              }
              return val
            },
          },
        },
        legend: {
          show: false,
        },
      },
      series: [
        {
          name: 'PayToken',
          data: [],
        },
      ],
      isReady: false,
    }
  },
  computed: {
    ...mapGetters(['currentUserCompanyModule']),
  },
  mounted() {
    const mq = window.matchMedia('(min-width: 480px)')

    if (!mq.matches) {
      this.options.chart.type = 'area'
      this.options.xaxis.labels.show = false
      this.options.yaxis.show = false
    }

    if (this.currentUserCompanyModule[0] === 'gaming')
      this.options.legend.show = true

    this.traffic.reverse()

    this.traffic.forEach((transaction) => {
      this.transactions.push({
        week: getWeek(
          parse(transaction.createdAt.split(' @')[0], 'dd.MM.yyyy', new Date()),
          {
            weekStartsOn: 1,
          }
        ),
        amount: transaction.amount,
        paymentType: transaction.paymentType,
      })
    })

    this.transactions.forEach((transaction) => {
      if (this.options.xaxis.categories.indexOf(transaction.week) === -1) {
        this.options.xaxis.categories.push(transaction.week)
        this.weeks.push(transaction.week)
      }
    })

    this.options.xaxis.categories.forEach((week) => {
      let weekTokenAmount = 0

      this.transactions.forEach((transaction) => {
        if (transaction.week === week) {
            if (transaction.paymentType === 'pay_token')
                weekTokenAmount += parseFloat(transaction.amount)
            }
      })

      this.series[0].data.push(weekTokenAmount)
    })

    setTimeout(() => {
      this.isReady = true
      setTimeout(() => {
        const weeks = getWeeksMeta(this.range.start, this.range.end)
        weeks[0].forEach((week) => {
          document.querySelectorAll('.week-label').forEach((element) => {
            let v = document.getElementById(
              element.getElementsByTagName('tspan')[0].id
            )
            if (!week.isFull) {
              if (week.week == v.innerHTML) {
                v.style.fill = '#ff0000'
                v.style.height = 200
                v.innerHTML = week.week + ' (Not Full Week)'
              }
            }
          })
        })
      }, 200)
    }, 500)
  },
}
</script>
