<template>
  <div>
    <div class="card card-custom gutter-b" style="height: 100%; overflow-y: auto;">
      <div class="card-header border-0 py-5">
        <h3 class="card-title font-weight-bolder text-dark">
          Average Report by hours of the day:
          <span class="ml-5"
            ><span
              class="label label-lg label-inline label-light-primary font-weight-bold"
              >{{ range.start | formatDate }}</span
            ></span
          >
          <span
            ><span class="flex-shrink-0 my-2 mx-3"
              ><i class="la la-arrow-right"></i></span
          ></span>
          <span
            ><span
              class="label label-lg label-inline label-light-primary font-weight-bold"
              >{{ range.end | formatDate }}</span
            ></span
          >
        </h3>
      </div>
      <div class="card-body">
        <apexchart
          v-if="isReady"
          :options="options"
          :series="series"
        ></apexchart>
      </div>
    </div>
    <div
      v-if="isShowDetailsVisible"
      id="showDetailsWrapper"
      class="card card-custom gutter-b"
    >
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <h3>Monday (Average)</h3>
          </div>
          <div class="col-12">
            <apexchart
              v-if="isMondaySeriesGenerated"
              :options="options"
              :series="mondaySeries"
            ></apexchart>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h3>Tuesday (Average)</h3>
          </div>
          <div class="col-12">
            <apexchart :options="options" :series="tuesdaySeries"></apexchart>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h3>Wednesday (Average)</h3>
          </div>
          <div class="col-12">
            <apexchart :options="options" :series="wednesdaySeries"></apexchart>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h3>Thursday (Average)</h3>
          </div>
          <div class="col-12">
            <apexchart :options="options" :series="thursdaySeries"></apexchart>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h3>Friday (Average)</h3>
          </div>
          <div class="col-12">
            <apexchart :options="options" :series="fridaySeries"></apexchart>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h3>Saturday (Average)</h3>
          </div>
          <div class="col-12">
            <apexchart :options="options" :series="saturdaySeries"></apexchart>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h3>Sunday (Average)</h3>
          </div>
          <div class="col-12">
            <apexchart :options="options" :series="sundaySeries"></apexchart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import parse from 'date-fns/parse'
import eachDayOfInterval from 'date-fns/eachDayOfInterval'
import isMonday from 'date-fns/isMonday'
import isTuesday from 'date-fns/isTuesday'
import isWednesday from 'date-fns/isWednesday'
import isThursday from 'date-fns/isThursday'
import isFriday from 'date-fns/isFriday'
import isSaturday from 'date-fns/isSaturday'
import isSunday from 'date-fns/isSunday'
import getHours from 'date-fns/getHours'
import VueApexCharts from 'vue-apexcharts'
import { dateFilter } from 'vue-date-fns'
import { mapGetters } from 'vuex'

export default {
  name: 'HoursTraffic',
  filters: {
    date: dateFilter,
  },
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    traffic: {
      type: Array,
    },
    range: {
      type: Object,
    },
  },
  data() {
    const hours = {}
    const days = {}
    const daysSeries = {}
    const dayNames = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
    for (let i = 0; i < 24; i++) {
      const key = `hour${i}`
      hours[key] = {
        token: { name: `hour${i}`, total: 0 },
      }
    }
    for (let i = 0; i < 7; i++) {
      const key = dayNames[i]
      days[key] = {...hours}
    }
    for (let i = 0; i < 7; i++) {
      const key = dayNames[i]
      daysSeries[`${key}Series`] = [
        {
          name: 'PayToken',
          data: [],
        },
      ]
    }
    return {
      transactions: [],
      tokenTransactions: [],
      ...hours,
      ...days,
      ...daysSeries,
      options: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            dataLabels: {
              orientation: 'vertical',
              position: 'bottom',
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: -2,
          offsetY: 7,
          formatter(val) {
            return (
              val
                .toFixed(2)
                .replace('.', ',')
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
            )
          },
        },
        xaxis: {
          categories: [
            '00h',
            '01h',
            '02h',
            '03h',
            '04h',
            '05h',
            '06h',
            '07h',
            '08h',
            '09h',
            '10h',
            '11h',
            '12h',
            '13h',
            '14h',
            '15h',
            '16h',
            '17h',
            '18h',
            '19h',
            '20h',
            '21h',
            '22h',
            '23h',
          ],
        },
        yaxis: {
          labels: {
            show: true,
            formatter(val) {
              return (
                val
                  .toFixed(2)
                  .replace('.', ',')
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
              )
            },
          },
        },
        grid: {
          show: true,
        },
        legend: {
          show: false,
        },
      },
      series: [
        {
          name: 'PayToken',
          data: [],
        },
      ],
      isMondaySeriesGenerated: false,
      isReady: false,
      isShowDetailsVisible: false,
    }
  },
  computed: {
    ...mapGetters(['currentUserCompanyModule']),
  },
  mounted() {
    if (this.currentUserCompanyModule[0] === 'gaming')
      this.options.legend.show = true

    this.traffic.forEach((transaction) => {
      this.transactions.push({
        hour: getHours(
          parse(
            transaction.createdAt.replace(' @ ', '-'),
            'dd.MM.yyyy-H:m:s',
            new Date()
          )
        ),
        amount: transaction.amount,
        paymentType: transaction.paymentType,
        date: parse(
          transaction.createdAt.replace(' @ ', '-'),
          'dd.MM.yyyy-H:m:s',
          new Date()
        ),
      })
    })
    this.tokenTransactions = this.transactions.filter(
      (transaction) => transaction.paymentType === 'pay_token'
    )

    this.tokenTransactions.forEach((transaction) => {
      const hour = transaction.hour;
      
      this[`hour${hour}`].token.total += transaction.amount;
      const days = [
        { fn: isMonday, name: 'monday' },
        { fn: isTuesday, name: 'tuesday' },
        { fn: isWednesday, name: 'wednesday' },
        { fn: isThursday, name: 'thursday' },
        { fn: isFriday, name: 'friday' },
        { fn: isSaturday, name: 'saturday' },
        { fn: isSunday, name: 'sunday' },
      ];

      days.forEach(({ fn, name }) => {
        if (fn(transaction.date)) {
          this[name][`hour${hour}`].token.total += parseFloat(transaction.amount);
        }
      });
    });

    for (let hour = 0; hour <= 23; hour++) {
      this.series[0].data.push(this[`hour${hour}`].token.total);
    }

    let monday = 0
    let tuesday = 0
    let wednesday = 0
    let thursday = 0
    let friday = 0
    let saturday = 0
    let sunday = 0
    eachDayOfInterval(this.range).forEach((day) => {
      if (isMonday(day)) monday++
      if (isTuesday(day)) tuesday++
      if (isWednesday(day)) wednesday++
      if (isThursday(day)) thursday++
      if (isFriday(day)) friday++
      if (isSaturday(day)) saturday++
      if (isSunday(day)) sunday++
    })
    const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
    const dayCounts = { monday, tuesday, wednesday, thursday, friday, saturday, sunday };
    days.forEach(day => {
        const totalDay = dayCounts[day];
        for (let hour = 0; hour <= 23; hour++) {
            this[`${day}Series`][0].data.push(this[day][`hour${hour}`].token.total / totalDay);
        }
    });

    this.isMondaySeriesGenerated = true; 
    this.isTuesdaySeriesGenerated = true; 
    this.isWednesdaySeriesGenerated = true; 
    this.isThursdaySeriesGenerated = true; 
    this.isFridaySeriesGenerated = true; 
    this.isSaturdaySeriesGenerated = true; 
    this.isSundaySeriesGenerated = true; 

    setTimeout(() => {
      this.isReady = true
    }, 500)
  },
  methods: {
    showDetails() {
      this.isShowDetailsVisible = true
      setTimeout(() => {
        document.getElementById('showDetailsWrapper').scrollIntoView({
          block: 'start',
          behavior: 'smooth',
        })
      }, 500)
    },
  },
}
</script>
