<template>
  <div style="width: 100%; height: 40vh;">
    <div class="card card-custom gutter-b" style="width: 100%; height: 40vh; overflow-y: auto;">
      <div class="card-header border-0 py-5 mb-12">
        <h3 class="card-title font-weight-bolder text-dark">
          Report By Transactions:
          <span class="label label-lg label-inline label-light-primary font-weight-bold ml-4">{{ traffic.length }}</span>
          <span class="ml-5">
            <span class="label label-lg label-inline label-light-primary font-weight-bold">{{ range.start | formatDate }}</span>
          </span>
          <span>
            <span class="flex-shrink-0 my-2 mx-3">
              <i class="la la-arrow-right"></i>
            </span>
          </span>
          <span>
            <span class="label label-lg label-inline label-light-primary font-weight-bold">{{ range.end | formatDate }}</span>
          </span>
        </h3>
        <div v-if="allRecords.length > 10" class="card-toolbar">
          <button @click="showAllRecords" class="btn btn-light-dark font-weight-bold px-5">
            Show All
          </button>
        </div>
      </div>
      <div class="card-body py-4">
        <div class="table-responsive mb-0 pb-0">
          <table class="table table-head-custom table-vertical-center table-head-bg">
            <thead>
              <tr class="text-uppercase">
                <th class="pl-6">
                  <span class="cursor-pointer">
                    <span>{{ $t('COMPANIES.DEVICE_NAME') }}</span>
                  </span>
                </th>
                <th class="pl-6">
                  <span @click="orderByImei" class="cursor-pointer">
                    <span class="pt-2" :class="{ 'text-primary': imeiOrder.isActive }">Imei</span>
                    <span class="svg-icon svg-icon-sm pl-2" :class="{ 'svg-icon-primary': imeiOrder.isActive }">
                      <inline-svg v-if="imeiOrder.query === 'asc'" src="media/svg/icons/Navigation/Down-2.svg" />
                      <inline-svg v-else src="media/svg/icons/Navigation/Up-2.svg" />
                    </span>
                  </span>
                </th>
                <th>
                  <span @click="orderByAmount" class="cursor-pointer">
                    <span class="pt-2" :class="{ 'text-primary': amountOrder.isActive }">{{ $t('COMPANIES.TOKEN_AMOUNT') }}</span>
                    <span class="svg-icon svg-icon-sm pl-2" :class="{ 'svg-icon-primary': amountOrder.isActive }">
                      <inline-svg v-if="amountOrder.query === 'asc'" src="media/svg/icons/Navigation/Down-2.svg" />
                      <inline-svg v-else src="media/svg/icons/Navigation/Up-2.svg" />
                    </span>
                  </span>
                </th>
                <th class="text-right">
                  <span @click="orderByPaymentType" class="cursor-pointer">
                    <span class="pt-2" :class="{ 'text-primary': paymentTypeOrder.isActive }">{{ $t('COMPANIES.TOKEN_TYPE') }}</span>
                    <span class="svg-icon svg-icon-sm pl-2" :class="{ 'svg-icon-primary': paymentTypeOrder.isActive }">
                      <inline-svg v-if="paymentTypeOrder.query === 'asc'" src="media/svg/icons/Navigation/Down-2.svg" />
                      <inline-svg v-else src="media/svg/icons/Navigation/Up-2.svg" />
                    </span>
                  </span>
                </th>
                <th class="text-right">
                  <span @click="orderByCreatedAt" class="cursor-pointer">
                    <span class="pt-2" :class="{ 'text-primary': createdAtOrder.isActive }">{{ $t('COMPANIES.DATE_AND_TIME') }}</span>
                    <span class="svg-icon svg-icon-sm pl-2" :class="{ 'svg-icon-primary': createdAtOrder.isActive }">
                      <inline-svg v-if="createdAtOrder.query === 'asc'" src="media/svg/icons/Navigation/Down-2.svg" />
                      <inline-svg v-else src="media/svg/icons/Navigation/Up-2.svg" />
                    </span>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(transaction, key) in shownRecords" :key="key">
                <td class="pl-6" :class="{ 'border-top-0': key === 0 }">
                  <span class="text-dark-75 font-weight-bolder mb-1 font-size-lg">{{ transaction.deviceName }}</span>
                  <div>
                    <span v-for="(tag, key) in transaction.tags" :key="key" class="label label-sm label-light-primary label-inline mr-2">{{ tag }}</span>
                  </div>
                </td>
                <td :class="{ 'border-top-0': key === 0 }">{{ transaction.imei }}</td>
                <td :class="{ 'border-top-0': key === 0 }">{{ transaction.amount }}</td>
                <td class="text-right" :class="{ 'border-top-0': key === 0 }">
                  <span v-if="transaction.paymentType === 'pay_token'" class="label label-lg label-inline label-light-primary">Pay Token</span>
                </td>
                <td class="text-right" :class="{ 'border-top-0': key === 0 }">
                  <span>{{ transaction.createdAt }}</span>
                </td>
              </tr>
              <tr class="mx-4">
                <td colspan="2" class="font-size-lg font-weight-bolder text-dark">Total:</td>
                <td colspan="3" class="font-size-lg font-weight-bolder text-dark text-right">{{ total }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.card-custom-fixed {
  height: 50vh;
  overflow-y: auto;
}
</style>

<script>
import { dateFilter } from 'vue-date-fns'
import { mapGetters } from 'vuex'
import axios from 'axios'

export default {
  name: 'TransactionsTraffic',
  filters: {
    date: dateFilter,
  },
  props: {
    traffic: {
      type: Array,
    },
    selected: {
      type: Array,
    },
    range: {
      type: Object,
    },
  },
  data() {
    return {
      shownRecords: [],
      first10Records: [],
      allRecords: [],
      base: 0,
      tax: 0,
      total: 0,
      isGenerated: false,
      showAll: false,
      imeiOrder: {
        query: 'asc',
        isActive: false,
      },
      amountOrder: {
        query: 'asc',
        isActive: false,
      },
      paymentTypeOrder: {
        query: 'asc',
        isActive: false,
      },
      createdAtOrder: {
        query: 'asc',
        isActive: false,
      },
    }
  },
  computed: {
    ...mapGetters(['currentUserCompanyOib', 'currentActiveModuleComponents']),
  },
  mounted() {
    this.traffic.forEach((transaction) => {
      if(transaction.paymentType === 'pay_token') {
        this.allRecords.push(transaction)

        this.base += parseFloat(transaction.amount) * 0.8
        this.tax +=
            parseFloat(transaction.amount) - parseFloat(transaction.amount) * 0.8
        this.total += parseFloat(transaction.amount)
        }
    })

    this.first10Records = this.allRecords.slice(0,10);
    this.shownRecords = this.first10Records;

    this.isGenerated = true
  },
  methods: {
    orderByImei() {
      this.imeiOrder.isActive = true

      if (this.showAll) {
        if (this.imeiOrder.query === 'asc') {
          this.shownRecords.sort((a, b) => a.imei - b.imei)
          this.imeiOrder.query = 'desc'
        } else {
          this.shownRecords.sort((a, b) => b.imei - a.imei)
          this.imeiOrder.query = 'asc'
        }
      } else {
        if (this.imeiOrder.query === 'asc') {
          this.shownRecords.sort((a, b) => a.imei - b.imei)
          this.imeiOrder.query = 'desc'
        } else {
          this.shownRecords.sort((a, b) => b.imei - a.imei)
          this.imeiOrder.query = 'asc'
        }
      }
    },
    orderByAmount() {
      this.amountOrder.isActive = true

      if (this.showAll) {
        if (this.amountOrder.query === 'asc') {
          this.amountOrder.query = 'desc'
          this.shownRecords.sort((a, b) => a.amount - b.amount)
        } else {
          this.amountOrder.query = 'asc'
          this.shownRecords.sort((a, b) => b.amount - a.amount)
        }
      } else {
        if (this.amountOrder.query === 'asc') {
          this.amountOrder.query = 'desc'
          this.shownRecords.sort((a, b) => a.amount - b.amount)
        } else {
          this.amountOrder.query = 'asc'
          this.shownRecords.sort((a, b) => b.amount - a.amount)
        }
      }
    },
    orderByPaymentType() {
      this.paymentTypeOrder.isActive = true

      if (this.showAll) {
        if (this.paymentTypeOrder.query === 'asc') {
          this.shownRecords.sort((a, b) => a.paymentType - b.paymentType)
          this.paymentTypeOrder.query = 'desc'
        } else {
          this.shownRecords.sort((a, b) => b.paymentType - a.paymentType)
          this.paymentTypeOrder.query = 'asc'
        }
      } else {
        if (this.paymentTypeOrder.query === 'asc') {
          this.shownRecords.sort((a, b) => a.paymentType - b.paymentType)
          this.paymentTypeOrder.query = 'desc'
        } else {
          this.shownRecords.sort((a, b) => b.paymentType - a.paymentType)
          this.paymentTypeOrder.query = 'asc'
        }
      }
    },
    orderByCreatedAt() {
      this.createdAtOrder.isActive = true

      if (this.showAll) {
        if (this.createdAtOrder.query === 'asc') {
          this.createdAtOrder.query = 'desc'
          this.shownRecords.sort((a, b) => a.createdAt - b.createdAt)
        } else {
          this.createdAtOrder.query = 'asc'
          this.shownRecords.sort((a, b) => b.createdAt - a.createdAt)
        }
      } else {
        if (this.createdAtOrder.query === 'asc') {
          this.createdAtOrder.query = 'desc'
          this.shownRecords.sort((a, b) => a.createdAt - b.createdAt)
        } else {
          this.createdAtOrder.query = 'asc'
          this.shownRecords.sort((a, b) => b.createdAt - a.createdAt)
        }
      }
    },
    exportAsExcel() {
      let ids = ''
      this.traffic.forEach((transaction) => {
        ids += transaction.id + ','
      })

      axios
        .get(
          `${
            process.env.VUE_APP_ROUTE_API_URL
          }/api/v1/transactions-v2/export/${ids.slice(0, -1)}`
        )
        .then(({ data }) => {
          if (data.status === 'ok') {
            window.location.replace(data.fileUrl)
            this.$notify({
              group: 'notification',
              type: 'success',
              title: 'Export success',
            })
          }
        })
    },
    showAllRecords() {
        if(this.showAll) {
            this.showAll = false;
            this.shownRecords = this.first10Records;
        } else {
            this.shownRecords = this.allRecords;
            this.showAll = true;
        }
    },
  },
}
</script>
