<template>
  <div>
    <div class="card card-custom gutter-b" style="width: 100%; height: 60vh; overflow-y: auto;">
      <div class="card-header border-0 py-5">
        <h3 class="card-title font-weight-bolder text-dark">
          <span>Average report by days in week:</span>
          <span>
            <span class="ml-5"
              ><span
                class="label label-lg label-inline label-light-primary font-weight-bold"
                >{{ range.start | formatDate }}</span
              ></span
            >
            <span
              ><span class="flex-shrink-0 my-2 mx-3"
                ><i class="la la-arrow-right"></i></span
            ></span>
            <span
              ><span
                class="label label-lg label-inline label-light-primary font-weight-bold"
                >{{ range.end | formatDate }}</span
              ></span
            >
          </span>
        </h3>
      </div>
      <div class="card-body pt-0">
        <VueApexCharts
          v-if="isReady"
          :options="options"
          :series="series"
        ></VueApexCharts>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import parse from 'date-fns/parse'
import getDay from 'date-fns/getDay'
import getWeek from 'date-fns/getWeek'
import { dateFilter } from 'vue-date-fns'
import format from 'date-fns/format'
import { mapGetters } from 'vuex'
import eachDayOfInterval from 'date-fns/eachDayOfInterval'

export default {
  name: 'WeekDaysTraffic',
  filters: {
    date: dateFilter,
  },
  components: {
    VueApexCharts,
  },
  props: {
    traffic: {
      type: Array,
    },
    range: {
      type: Object,
    },
  },
  data() {
    return {
      transactions: [],
      tokenTransactions: [],
      days: [],
      day1: {
        token: { name: 'day1', total: 0 },
      },
      day2: {
       token: { name: 'day2', total: 0 },
      },
      day3: {
       token: { name: 'day3', total: 0 },
      },
      day4: {
       token: { name: 'day4', total: 0 },
      },
      day5: {
       token: { name: 'day5', total: 0 },
      },
      day6: {
       token: { name: 'day6', total: 0 },
      },
      day0: {
       token: { name: 'day0', total: 0 },
      },
      options: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 8,
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: -2,
          offsetY: 7,
          formatter(val) {
            return (
              val
                .toFixed(2)
                .replace('.', ',')
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
            )
          },
        },
        xaxis: {
          categories: [
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
            'Sunday',
          ],
          labels: {
            show: true,
          },
        },
        yaxis: {
          show: true,
          labels: {
            show: true,
            formatter(val) {
              return (
                val
                  .toFixed(2)
                  .replace('.', ',')
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
              )
            },
          },
        },
        grid: {
          show: true,
        },
        legend: {
          show: false,
        },
      },
      series: [
        {
          name: 'PayToken',
          data: [],
        },
      ],
      isReady: false,
    }
  },
  computed: {
    ...mapGetters(['currentUserCompanyModule']),
  },
  mounted() {
    const mq = window.matchMedia('(min-width: 480px)')
    if (!mq.matches) {
      this.options.chart.type = 'area'
      this.options.xaxis.labels.show = false
      this.options.yaxis.show = false
    }

    if (this.currentUserCompanyModule[0] === 'gaming')
      this.options.legend.show = true

    this.traffic.forEach((transaction) => {
      this.transactions.push({
        day: parse(
          transaction.createdAt.split(' @')[0],
          'dd.MM.yyyy',
          new Date()
        ),
        week: getWeek(
          parse(transaction.createdAt.split(' @')[0], 'dd.MM.yyyy', new Date())
        ),
        amount: transaction.amount,
        paymentType: transaction.paymentType,
      })
    })

    this.tokenTransactions = this.transactions.filter(
      (transaction) => transaction.paymentType === 'pay_token'
    )

    const dayTotals = Array(7).fill(0);

    this.tokenTransactions.forEach(transaction => {
      const thisDay = getDay(new Date(transaction.day));
      dayTotals[thisDay] += parseFloat(transaction.amount);
    });

    const dayCounts = Array(7).fill(0);
    eachDayOfInterval(this.range).forEach(day => {
      const dayIndex = getDay(day);
      dayCounts[dayIndex]++;
    });

    dayCounts.forEach((count, index) => {
      this.series[0].data.push(count ? dayTotals[index] / count : 0);
    });

    setTimeout(() => {
      this.isReady = true
    }, 500)
  },
  methods: {
    exportAsExcel() {
      const after = format(this.range.start, 'dd_MM_yyyy')
      const before = format(this.range.end, 'dd_MM_yyyy')
      const fileFormat = 'xlsx'
      const exportSelectedOnly = false
      const filename = 'report_by_device' + after + '_' + before
      this.$refs.location.exportTable(fileFormat, exportSelectedOnly, filename)
    },
  },
}
</script>
